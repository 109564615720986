<template>
  <div :class="[{'product-section':!isMobile},{'product-section-mobile':isMobile}]">

    <div v-if="!isMobile" class="table product-reveal">

      <div class="table-header">
        <p class="left-title">{{ $t('home.product.stack') }}</p>
        <p class="right-title">{{ $t('home.product.architecture') }}</p>
      </div>

      <div class="row">
        <div class="left">
          <img class="icon" src="@/assets/images/home/layer_1_icon.png" alt="">
          <p class="title">{{ $t('home.product.layer1Title') }}</p>
        </div>

        <div class="right">
          <div>
            <p class="title">{{ $t('home.product.layer1Content.web2') }}</p>
            <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer1Content.api') }}</p>
          </div>

          <div style="margin-left: 0.4rem">
            <p class="title">{{ $t('home.product.layer1Content.web3') }}</p>
            <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer1Content.api') }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="left">
          <img class="icon" src="@/assets/images/home/layer_2_icon.png" alt="">
          <p class="title">{{ $t('home.product.layer2Title') }}</p>
        </div>

        <div class="right">
          <ul>
            <li class="text clearfix">{{ $t('home.product.layer2Content.content1') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer2Content.content2') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer2Content.content3') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer2Content.content4') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer2Content.content5') }}</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="left">
          <img class="icon" src="@/assets/images/home/layer_3_icon.png" alt="">
          <p class="title">{{ $t('home.product.layer3Title') }}</p>
        </div>

        <div class="right">
          <ul>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content1') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content2') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content3') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content4') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content5') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content6') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content7') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content8') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content9') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer3Content.content10') }}</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="left">
          <img class="icon" src="@/assets/images/home/layer_4_icon.png" alt="">
          <p class="title">{{ $t('home.product.layer4Title') }}</p>
        </div>

        <div class="right">
          <ul>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content1') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content2') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content3') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content4') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content5') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content6') }}</li>
            <li class="text clearfix">{{ $t('home.product.layer4Content.content7') }}</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="left">
          <img class="icon" src="@/assets/images/home/layer_5_icon.png" alt="">
          <p class="title">{{ $t('home.product.layer5Title') }}</p>
        </div>

        <div class="right">
          <div>
            <p class="title">{{ $t('home.product.layer5Content.web2') }}</p>
            <p class="text" style="margin-top: 0.08rem; white-space: pre-line;">{{ $t('home.product.layer5Content.content1') }}</p>
          </div>

          <div style="margin-left: 0.4rem;">
            <p class="title">{{ $t('home.product.layer5Content.web3') }}</p>
            <p class="text" style="margin-top: 0.08rem; white-space: pre-line;">{{ $t('home.product.layer5Content.content2') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobile" class="mobile-title">{{ $t('home.product.mobileTitle') }}</div>

    <el-collapse v-if="isMobile" class="collapse" v-model="activeName" accordion style="width: 100%" @change="onChange">

      <el-collapse-item name="1" class="product-reveal item1">

        <div style="width:100%;" slot="title">

          <div class="collapse-item-head">

            <div class="left">
              <img class="icon" src="@/assets/images/home/layer_1_icon.png" alt="">
              <p class="title">{{ $t('home.product.layer1Title') }}</p>
            </div>

            <img ref="arrow" class="arrow" :class="{'is-active': activeName === '1'}" src="@/assets/images/home/arrow_down_green.svg" alt="">

          </div>

        </div>

        <div slot>
          <div class="collapse-item-content">

            <div class="divider"></div>

            <div style="margin-left: 0.16rem">
              <p class="title" style="margin-top: 0.12rem">{{ $t('home.product.layer1Content.web2') }}</p>
              <p class="text" style="margin-top: 0.02rem">{{ $t('home.product.layer1Content.api') }}</p>
            </div>

            <div style="margin-left: 0.16rem;margin-bottom: 0.2rem">
              <p class="title" style="margin-top: 0.12rem">{{ $t('home.product.layer1Content.web3') }}</p>
              <p class="text" style="margin-top: 0.02rem">{{ $t('home.product.layer1Content.api') }}</p>
            </div>
          </div>
        </div>

      </el-collapse-item>

      <el-collapse-item name="2" class="product-reveal item2">

        <div style="width:100%;" slot="title">

          <div class="collapse-item-head">

            <div class="left">
              <img class="icon" src="@/assets/images/home/layer_2_icon.png" alt="">
              <p class="title">{{ $t('home.product.layer2Title') }}</p>
            </div>

            <img ref="arrow" class="arrow" :class="{'is-active': activeName === '2'}" src="@/assets/images/home/arrow_down_green.svg" alt="">

          </div>

        </div>

        <div slot>
          <div class="collapse-item-content">
            <div class="divider"></div>
            <div style="margin-left: 0.16rem;margin-bottom: 0.2rem">
              <p class="text" style="margin-top: 0.12rem">{{ $t('home.product.layer2Content.content1') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer2Content.content2') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer2Content.content3') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer2Content.content4') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer2Content.content5') }}</p>
            </div>

          </div>
        </div>

      </el-collapse-item>

      <el-collapse-item name="3" class="product-reveal item3">

        <div style="width:100%;" slot="title">

          <div class="collapse-item-head">

            <div class="left">
              <img class="icon" src="@/assets/images/home/layer_3_icon.png" alt="">
              <p class="title">{{ $t('home.product.layer3Title') }}</p>
            </div>

            <img ref="arrow" class="arrow" :class="{'is-active': activeName === '3'}" src="@/assets/images/home/arrow_down_green.svg" alt="">

          </div>

        </div>

        <div slot>
          <div class="collapse-item-content">

            <div class="divider"></div>

            <div style="margin-left: 0.16rem;margin-bottom: 0.2rem">
              <p class="text" style="margin-top: 0.12rem">{{ $t('home.product.layer3Content.content1') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content2') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content3') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content4') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content5') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content6') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content7') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content8') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content9') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer3Content.content10') }}</p>
            </div>
          </div>
        </div>

      </el-collapse-item>

      <el-collapse-item name="4" class="product-reveal item4">

        <div style="width:100%;" slot="title">

          <div class="collapse-item-head">

            <div class="left">
              <img class="icon" src="@/assets/images/home/layer_4_icon.png" alt="">
              <p class="title">{{ $t('home.product.layer4Title') }}</p>
            </div>

            <img ref="arrow" class="arrow" :class="{'is-active': activeName === '4'}" src="@/assets/images/home/arrow_down_green.svg" alt="">

          </div>

        </div>

        <div slot>
          <div class="collapse-item-content">

            <div class="divider"></div>

            <div style="margin-left: 0.16rem;margin-bottom: 0.2rem">
              <p class="text" style="margin-top: 0.12rem">{{ $t('home.product.layer4Content.content1') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer4Content.content2') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer4Content.content3') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer4Content.content4') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer4Content.content5') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer4Content.content6') }}</p>
              <p class="text" style="margin-top: 0.08rem">{{ $t('home.product.layer4Content.content7') }}</p>
            </div>
          </div>
        </div>

      </el-collapse-item>

      <el-collapse-item name="5" class="product-reveal item5">

        <div style="width:100%;" slot="title">

          <div class="collapse-item-head">

            <div class="left">
              <img class="icon" src="@/assets/images/home/layer_5_icon.png" alt="">
              <p class="title">{{ $t('home.product.layer5Title') }}</p>
            </div>

            <img ref="arrow" class="arrow" :class="{'is-active': activeName === '5'}" src="@/assets/images/home/arrow_down_green.svg" alt="">

          </div>

        </div>

        <div slot>
          <div class="collapse-item-content">

            <div class="divider"></div>

            <div style="margin-left: 0.16rem">
              <p class="title" style="margin-top: 0.12rem">{{ $t('home.product.layer5Content.web2') }}</p>
              <p class="text" style="margin-top: 0.02rem">{{ $t('home.product.layer5Content.mobileContent1') }}</p>
            </div>

            <div style="margin-left: 0.16rem;margin-bottom: 0.2rem">
              <p class="title" style="margin-top: 0.12rem">{{ $t('home.product.layer5Content.web3') }}</p>
              <p class="text" style="margin-top: 0.02rem">{{ $t('home.product.layer5Content.mobileContent2') }}</p>
            </div>
          </div>
        </div>

      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";

export default {
  name: "ArchitectureSection",
  components: {

  },
  data() {
    return {
      scrollReveal: ScrollReveal(),
      activeName: '',
    }
  },
  mounted() {
    this.scrollReveal.reveal('.product-reveal', {
      // 动画的时长
      // duration: 2000,
      // 延迟时间
      // delay: 2000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      // origin: 'top',
      // 回滚的时候是否再次触发动画
      reset: false,
      scale: 1,
      opacity: 1,
      beforeReveal: function (el) {
        //添加动画
        el.classList.add('animate__animated', 'animate__fadeInUp');
      },
      beforeReset: function (el) {
        //移除动画，否则会冲突
        el.classList.remove('animate__animated')
        el.classList.remove('animate__fadeInUp')
      },
    });
  },
  methods: {
    onChange(activeNames){
      console.log("onChange activeNames == "+activeNames)
    },
  }
}
</script>

<style lang="scss" scoped>
.product-section {
  width: 12rem;
  margin: 0 auto;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 0.8rem;

  .table{
    background: #FFFFFF;
    box-shadow: 0 0.07rem 0.18rem 0 rgba(0,49,44,0.0800);
    border-radius: 0.12rem;

    .table-header{
      width: 12rem;
      height: 0.7rem;
      background: linear-gradient(134deg, #12EAC4 0%, #0199EF 100%);
      border-radius: 0.12rem 0.12rem 0 0;
      display: flex;
      align-items: center;
      font-size: 0.22rem;
      font-family: PoppinsBold, sans-serif;
      color: #FFFFFF;
      line-height: 0.4rem;
      .left-title{
        width: 4.6rem;
        padding-left: 0.49rem;
      }

      .right-title{
        width: 7.4rem;
      }
    }

    .row{
      display: flex;
      align-items: center;
      &:nth-child(2n+1) {
        background: linear-gradient(134deg, rgba(18,234,196,0.0500) 0%, rgba(1,153,239,0.0500) 100%);
      }

      .left{
        width: 4.6rem;
        padding-left: 0.49rem;
        display: flex;
        align-items: center;

        .icon{
          width: 0.56rem;
          height: 0.56rem;
        }
        .title{
          margin-left: 0.16rem;
          font-size: 0.18rem;
          font-family: PoppinsBold, sans-serif;
          color: #020027;
          line-height: 0.29rem;
        }
      }

      .right{
        display: flex;
        align-items: center;
        width: 7.4rem;
        margin: 0.25rem 0;

        ul>li{
          list-style:none;
          float: left;
          line-height: 0.25rem;
          padding: 0.02rem 0.4rem 0.02rem 0;
          &:last-child {
            padding: 0.02rem 0;
          }
        }

        .title {
          font-size: 0.16rem;
          font-family: PoppinsBold, sans-serif;
          color: #020027;
          line-height: 0.29rem;
        }

        .text {
          font-size: 0.14rem;
          font-family: PoppinsRegular, sans-serif;
          color: #666666;
          line-height: 0.25rem;
        }
      }
    }
  }
}

.product-section-mobile {
  width: 100%;
  padding: 0.4rem 0.2rem 0.4rem;

  .mobile-title{
    font-size: 0.24rem;
    font-family: PoppinsBold, sans-serif;
    color: #020027;
    line-height: 0.36rem;
    letter-spacing: 1px;
    white-space: pre-line;
  }

  .collapse{
    display: block;
    .item1{
      background: #FFFFFF url('~@/assets/images/home/layer_1_bg.png') no-repeat calc(100% + 0.1rem) -0.03rem;
      background-size: 1rem 1rem;
    }
    .item2{
      background: #FFFFFF url('~@/assets/images/home/layer_2_bg.png') no-repeat calc(100% + 0.1rem) -0.03rem;
      background-size: 1rem 1rem;
    }
    .item3{
      background: #FFFFFF url('~@/assets/images/home/layer_3_bg.png') no-repeat calc(100% + 0.1rem) -0.03rem;
      background-size: 1rem 1rem;
    }
    .item4{
      background: #FFFFFF url('~@/assets/images/home/layer_4_bg.png') no-repeat calc(100% + 0.1rem) -0.03rem;
      background-size: 1rem 1rem;
    }
    .item5{
       background: #FFFFFF url('~@/assets/images/home/layer_5_bg.png') no-repeat calc(100% + 0.1rem) -0.03rem;
       background-size: 1rem 1rem;
    }


    .collapse-item-head{
      height: 0.88rem;
      width:100%;
      position: relative;


      .left{
        margin-left: 0.16rem;
        display: flex;
        flex-direction: column;

        .icon{
          width: 0.32rem;
          height: 0.32rem;
          margin-top: 0.12rem;
        }
        .title{
          margin-top: 0.1rem;
          font-size: 0.14rem;
          line-height: 0.23rem;
          font-family: PoppinsBold, sans-serif;
          color: #020027;
        }
      }

      .arrow{
        width: 0.18rem;
        height: 0.18rem;
        transition: all 0.5s;
        position: absolute;
        right: 0.3rem;
        top:50%;
        transform:translate(0,-50%) rotate(0deg);

        &.is-active{
          transform:translate(0,-50%) rotate(180deg);
        }
      }
    }

    .collapse-item-content{

      .divider{
        width: calc(100% - 0.32rem);
        height: 0.01rem;
        margin-left: 0.16rem;
        background: rgba(211,211,211,0.5000);
      }

      .title{
        font-size: 0.13rem;
        font-family: PoppinsMedium, sans-serif;
        font-weight: 500;
        color: #020027;
        line-height: 0.21rem;
      }

      .text{
        font-size: 0.12rem;
        font-family: PoppinsRegular, sans-serif;
        font-weight: 400;
        color: #666666;
        line-height: 0.19rem;
        white-space: pre-line;
      }
    }
  }
}

::v-deep(.el-collapse){
  border-top: 0;
  border-bottom: 0;
  margin-top: 0.3rem;
}

::v-deep(.el-collapse-item){
  margin-bottom: 0.14rem;
  border-radius: 0.12rem;
  box-shadow: 0 0.08rem 0.2rem -0.08rem rgba(16,16,16,0.0600);
  overflow: hidden;
}

::v-deep(.el-collapse-item__header){
  height: 0.88rem;
  border-bottom: 0;
  background-color: transparent;
}

::v-deep(.el-collapse-item__arrow){
  display: none;
}

::v-deep(.el-collapse-item__wrap){
  border-bottom: 0;
  border-radius: 0 0 0.12rem 0.12rem;
  background-color: transparent;
}

::v-deep(.el-collapse-item__content){
  padding: 0!important;
}
</style>
