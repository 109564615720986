<template>
  <div :class="[{'content':!isMobile},{'content-mobile':isMobile}]">

    <div class="image-wrap">
      <FirstViewElements class="first-element"></FirstViewElements>
    </div>

    <div class="banner-content-wrap">
      <div class="banner-content">
        <div class="slogan"><span :style="{'font-size':isMobile?'0.38rem': '0.8rem'}">C</span>{{ $t('home.slogan1') }}</div>
        <div class="slogan" style="margin-top: 0"><span :style="{'font-size':isMobile?'0.38rem': '0.8rem'}">S</span>{{ $t('home.slogan2') }}</div>
        <div class="divider"></div>
        <div class="description">{{ $t(isMobile?'home.description_mobile':'home.description') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FirstViewElements from "@/pages/home/FirstViewElements";

export default {
  name: "FirstView",
  props: ["isPlay"],
  components:{
    FirstViewElements
  },
  watch: {
    isPlay(newVal, oldVal) {
      console.log("isPlay newVal == "+newVal +" oldVal == "+oldVal);
    }
  },
  data() {
    return {
      elementImgUrl:""
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

.content {
  width: 100%;
  height: 8.5rem;
  background: #020027;
  position: relative;
  overflow: hidden;

  .image-wrap{
    width: 12rem;
    margin: 0 auto;

    .first-element{
      float: right;
      margin-right: -0.8rem;
      margin-top: 1.35rem;
      margin-bottom: 0.69rem;
    }

    .banner-element {
      float: right;
      width: 7rem;
      margin-right: -1.2rem;
      margin-top: 2.6rem;
      margin-bottom: 0.5rem;
      //animation:float-move 5s infinite;
    }
  }

  .banner-content-wrap{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    .banner-content {
      width: 12rem;
      margin: 0 auto;

      .slogan {
        margin-top: 2rem;
        font-size: 0.66rem;
        font-family: PoppinsBold, sans-serif;
        color: #FFFFFF;
        line-height: 0.9rem;
        white-space: pre-line;
      }

      .divider {
        width: 0.61rem;
        height: 0.05rem;
        background: linear-gradient(270deg, #12EAC4 0%, #0199EF 100%);
        border-radius: 0.03rem;
        margin-top: 0.46rem;
      }

      .description {
        margin-top: 0.46rem;
        font-size: 0.18rem;
        font-family: PoppinsRegular, sans-serif;
        font-weight: 500;
        line-height: 0.29rem;
        white-space: pre-line;
        color: #E2E2E2;
      }
    }
  }

}

.content-mobile {
  width: 100%;
  background: #020027;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 8.12rem;

  .image-wrap{
    width: 100%;
    position: absolute;
    top: 2.52rem;

    .first-element{
      width: 100%;
    }
  }

  .banner-content-wrap{
    width: 100%;
    top: 1.15rem;
    position: absolute;

    .banner-content {
      width: 100%;

      .slogan {
        margin-left: 0.2rem;
        font-size: 0.32rem;
        font-family: PoppinsBold, sans-serif;
        color: #FFFFFF;
        line-height: 0.5rem;
        white-space: pre-line;
      }

      .divider {
        margin-top: 0.4rem;
        width: 0.56rem;
        height: 0.04rem;
        background: linear-gradient(270deg, #12EAC4 0%, #0199EF 100%);
        border-radius: 0.03rem;
        margin-left: 0.2rem;
      }

      .description {
        margin-top: 0.4rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        font-family: PoppinsRegular, sans-serif;
        white-space: pre-line;
        font-size: 0.14rem;
        font-weight: 400;
        color: #E2E2E2;
        line-height: 0.23rem;
      }
    }
  }

}

@keyframes float-move {
  from,
  to {
    -webkit-transform: translate3d(-5px, -5px, 0);
    transform: translate3d(-5px, -5px, 0);
  }

  50% {
      -webkit-transform: translate3d(5px, 5px, 0);
      transform: translate3d(5px, 5px, 0);
  }
}
</style>
