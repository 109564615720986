<template>

  <div :class="[{'solution':!isMobile},{'solution-mobile':isMobile}]">
    <section ref="header" class="header" :style="{'background': isHeaderDark ? '#020027' : '#FFFFFF' }">
      <div class="header-content">
        <img class="logo"
             :src="isHeaderDark?require('@/assets/images/home/logo.png'):require('@/assets/images/home/logo_dark.png')"
             alt=""
             @click="clickLogo"
        >

        <div class="header-tabs">
          <div class="header-tabs-item" @click="tabItemClick(0)">{{ $t('home.tabs.poc') }}</div>
          <div class="header-tabs-item" @click="tabItemClick(1)" style="color: #0FDDCA">{{ $t('home.tabs.solution') }}</div>
          <div class="header-tabs-item" @click="tabItemClick(2)">{{ $t('home.tabs.product') }}</div>
          <div class="header-tabs-item" @click="tabItemClick(3)">{{ $t('home.tabs.ecosystem') }}</div>
          <HeaderSelector :labelColor="tabLabelColor" :isHeaderDark="false"></HeaderSelector>
        </div>

        <div v-if="!isMobile" class="media-section">
          <img
              :src="isHeaderDark?require('@/assets/images/home/telegram.svg'):require('@/assets/images/home/telegram_dark.svg')"
              alt="" @click="mediaLink('Telegram')">
          <img
              :src="isHeaderDark?require('@/assets/images/home/twitter.svg'):require('@/assets/images/home/twitter_dark.svg')"
              alt="" @click="mediaLink('Twitter')">
          <img
              :src="isHeaderDark?require('@/assets/images/home/discord.svg'):require('@/assets/images/home/discord_dark.svg')"
              alt="" @click="mediaLink('Discord')">
          <img
              :src="isHeaderDark?require('@/assets/images/home/medium.svg'):require('@/assets/images/home/medium_dark.svg')"
              alt="" @click="mediaLink('Medium')">
        </div>

      </div>
    </section>

    <!-- Main -->
    <main class="main-content" ref="main">
      <section class="main-section" ref="section1">
        <Section1></Section1>
      </section>
      <section class="main-section" ref="section2">
        <Section2></Section2>
      </section>
    </main>

  </div>

</template>

<script>
import {scrollToY} from "@/utils";
import Section1 from "@/pages/solution/Section1";
import Section2 from "@/pages/solution/Section2";
import {media_util} from "@/mixins/mixin";
import HeaderSelector from "@/pages/home/HeaderSelector";

export default {
  name: "SolutionPage",
  mixins: [media_util],
  components: {
    Section1,
    Section2,
    HeaderSelector,
  },
  data() {
    return {
      isHeaderDark: false,
      tabLabelColor: "#020027",
    }
  },
  computed: {},
  mounted() {
    scrollToY(0, 0);
  },
  methods: {
    clickLogo() {
      // scrollToY(0, 500);
      this.tabItemClick(-1)
    },
    tabItemClick(index){
      // console.log(index)
      this.$route.params.tabIndex = index;
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>

.solution {
  .header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;

    .header-content {
      width: 12rem;
      height: 0.8rem;
      display: flex;
      margin: 0 auto;
      align-items: center;
      position: relative;

      .logo {
        width: 1.6rem;
        height: 0.35rem;
        position: absolute;
        left: 0;
        cursor: pointer;
      }

      .header-tabs {
        display: flex;
        margin: 0 auto;

        .header-tabs-item{
          padding: 0 .15rem;
          text-align: center;
          font-family: PoppinsBold, sans-serif;
          color: #020027;
          line-height: .26rem;
          font-size: .16rem;
          -webkit-text-size-adjust: none;
          cursor: pointer;
        }
      }

      .media-section {
        display: flex;
        position: absolute;
        right: 0;

        img {
          width: 0.29rem;
          height: 0.28rem;
          margin-left: 0.17rem;
          cursor: pointer;
        }
      }
    }
  }

  .main-content {
    overflow: hidden;
    background: #FAFAFA;

    .main-section {
      display: flex;
    }
  }
}

.solution-mobile {
  .header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;

    .header-content {
      width: 100%;
      height: 0.56rem;
      display: flex;
      margin: 0 auto;
      align-items: center;
      position: relative;

      .logo {
        width: 1.07rem;
        height: 0.24rem;
        position: absolute;
        left: 0.2rem;
        cursor: pointer;
      }

      .header-tabs {
        display: none;
      }

      .media-section {
        display: flex;
        position: absolute;
        right: 0;

        img {
          width: 0.29rem;
          height: 0.28rem;
          margin-left: 0.17rem;
          cursor: pointer;
        }
      }
    }
  }

  .main-content {
    overflow: hidden;
    background: #FAFAFA;

    .main-section {

    }
  }
}
</style>
