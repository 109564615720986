<template>
  <!--
  <div>
    <div class="swiper-container swiper1">

      <div class="swiper-wrapper">
        <div class="swiper-slide">

        </div>
        <div class="swiper-slide"></div>

      </div>
      &lt;!&ndash; 如果需要分页器 &ndash;&gt;
      <div class="swiper-pagination flex-row items-center swiper-pagination-custom"></div>

      &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
  -->

  <div class="solution-reveal" :class="[{'solution-section':!isMobile},{'solution-section-mobile':isMobile}]">

    <div class="description">
      <div class="title">
        {{$t('home.solutionTitle1')}}
        <br>
        <span>{{$t('home.solutionTitle2')}}</span>
      </div>
      <div class="content">{{$t('home.solutionContent')}}</div>
      <div v-if="!isMobile" class="learn" @click="learnMore">{{$t('home.learnMore')}}</div>
    </div>

    <img class="image" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/solution_220930.png" alt="">

    <div v-if="isMobile" class="learn" @click="learnMore">{{$t('home.learnMore')}}</div>
  </div>
</template>

<script>

// import 'swiper/dist/js/swiper'
// import 'swiper/dist/css/swiper.css'
// import Swiper from "swiper"

import ScrollReveal from "scrollreveal";

export default {
  name: "SolutionsSection",
  data(){
    return{
      scrollReveal:ScrollReveal()
    }
  },
  components: {},
  mounted() {
    this.scrollReveal.reveal('.solution-reveal', {
      // 动画的时长
      // duration: 2000,
      // 延迟时间
      // delay: 2000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      // origin: 'top',
      // 回滚的时候是否再次触发动画
      reset: false,
      scale: 1,
      opacity: 1,
      beforeReveal: function (el) {
        //添加动画
        el.classList.add('animate__animated', 'animate__fadeInUp');
      },
      beforeReset: function (el) {
        //移除动画，否则会冲突
        el.classList.remove('animate__animated')
        el.classList.remove('animate__fadeInUp')
      },
    });
    /*
    let swiper = new Swiper('.swiper1', {
      touchRatio: 1,
      direction: 'horizontal', // 垂直切换选项
      loop: true, // 循环模式选项
      //页面中有两个以上swiper，需要添加下面两项
      observer: true,
      observeParents: true,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true, // 分页器可以点击
        bulletClass: "custom-pagination",
        bulletActiveClass: "custom-pagination-active",
        // renderBullet: function (index, className) {
        //   return (
        //       '<span class="' + className + '" ></span>'
        //   );
        // },
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    this.swiperMouseOver(swiper);
    */
  },
  methods: {
    /*
    swiperMouseOver(swiper) {
      for (let i = 0; i < swiper.pagination.bullets.length; i++) {
        swiper.pagination.bullets[i].onmouseover = function () {
          this.click();
        };
      }
    }
    */

    learnMore(){
      this.$router.push({path: '/solution'})

      // let routeUrl = this.$router.resolve({path: "/solution"});
      // window.open(routeUrl.href, "_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
.solution-section{
  width: 12rem;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 0.6rem;
  padding-bottom: 1.2rem;

  .image{
    width: 6.4rem;
    //height: 3.92rem;
  }

  .description{
    width: 4.78rem;
    align-items: center;

    .title{
      font-size: 0.4rem;
      font-family: PoppinsBold,sans-serif;
      color: #0FDDCA;
      line-height: 0.56rem;
      letter-spacing: 1px;
      white-space: pre-line;
      span{
        color: #020027;
      }
    }

    .content{
      margin-top: 0.34rem;
      font-size: 0.15rem;
      font-family: PoppinsRegular,sans-serif;
      font-weight: 400;
      color: #5D5D5D;
      line-height: 0.28rem;
    }

    .learn{
      margin-top: 0.48rem;
      height: 0.48rem;
      padding: 0 0.24rem;
      background: #00E5CA;
      box-shadow: 0 0.1rem 0.3rem 0 rgba(0,229,202,0.2000);
      border-radius: 0.24rem;
      float: left;
      display: flex;
      align-items: center;
      cursor: pointer;

      font-size: 0.18rem;
      font-family: PoppinsBold,sans-serif;
      color: #FFFFFF;
      line-height: 0.29rem;
      text-shadow: 0 0 0 rgba(0,229,202,0.2000);
    }
  }
}

.solution-section-mobile{
  width: 100%;
  padding: 0.6rem 0.2rem 0.5rem;
  display: block;

  .image{
    width: calc(100% - 0.2rem);
    margin-top: 0.4rem;
    padding: 0.1rem;
    //background: #FFFFFF;
    //box-shadow: 0 0.08rem 0.2rem -0.08rem rgba(16,16,16,0.0600);
    //border-radius: 0.12rem;
  }

  .learn{
    margin: 0.2rem auto 0;
    height: 0.48rem;
    padding: 0 0.24rem;
    background: #00E5CA;
    box-shadow: 0 0.1rem 0.3rem 0 rgba(0,229,202,0.2000);
    border-radius: 0.24rem;

    width:fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;

    font-family: PoppinsBold,sans-serif;
    color: #FFFFFF;
    text-shadow: 0 0 0 rgba(0,229,202,0.2000);
    font-size: 0.16rem;
    line-height: 0.26rem;
  }

  .description{
    width: 100%;
    align-items: center;

    .title{
      font-family: PoppinsBold,sans-serif;
      color: #0FDDCA;
      letter-spacing: 1px;
      white-space: pre-line;
      font-size: 0.24rem;
      line-height: 0.36rem;

      span{
        color: #020027;
      }
    }

    .content{
      margin-top: 0.3rem;
      font-family: PoppinsRegular,sans-serif;
      font-weight: 400;
      color: #5D5D5D;
      line-height: 0.28rem;
      font-size: 0.14rem;
    }
  }
}
</style>
