import {isMobile} from "@/utils";

export const string_util = {
    methods: {
        isStrEmpty(str) {
            if (str === undefined || str === null || str.length === 0) {
                return true;
            } else {
                return false;
            }
        }
    },
}

export const address_filter = {
    methods: {
        addressFilter(val) {
            if (!val) {
                return '';
            } else if (String(val).length <= 13) {
                return val;
            }
            return val.substr(0, 6) + '...' + val.substr(-4);
        },
        addressEndFilter(val) {
            if (!val) {
                return '';
            } else if (String(val).length <= 36) {
                return val;
            }
            return val.substr(0, 36) + '...';
        }
    },
}

export const media_util = {
    methods: {
        mediaLink(media) {
            if (media === 'Twitter') {
                window.open("https://twitter.com/CyberCrowdOrg");
            } else if (media === 'Telegram') {
                window.open("https://t.me/cybercrowd_org");
            } else if (media === 'Discord') {
                window.open("https://discord.gg/xkVprQNH3n");
            } else if (media === 'Medium') {
                window.open("https://medium.com/@cybercrowd");
            }
        },
        downloadResources(resource) {
            if (resource === 'WhitePaper') {
                window.open("https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/resources/CyberCrowdWhitePaper_230627.pdf");
            } else if (resource === 'PPT') {
                window.open("https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/resources/CyberCrowdPitchDeck_230627.pdf");
            } else if (resource === 'OnePage') {
                window.open("https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/resources/CyberCrowdOnePage_221001.png");
            }
            this.selectorVisible = false;
        }
    },
}

export const mobile_util = {
    data() {
        return {
            isMobile: false
        };
    },
    mounted() {
        this.isMobile = isMobile((small) => {
            console.log("isMobile == " + small)
            this.isMobile = small
        })
    },
}
