module.exports = {
    common:{
        indexTitle:"CyberCrowd-Collaborate with all, Simplify the world",
        solutionTitle:"Collaboration-focused solutions",
        company:"2023 CYBER CROWD FOUNDATION LTD.",
        businessEmail:"business@cybercrowd.org"
    },
    home: {
        tabs:{
            poc:"PoC",
            solution:"Solution",
            product:"Product",
            ecosystem:"Ecosystem",
            team:"Team",
            download:"Download",
            more:"More",
            whitePaper:"Whitepaper",
            ppt:"Pitch Deck",
            onePage:"One Page",
        },
        slogan1: 'ollaborate with all',
        slogan2: 'implify the world',
        description: 'To create a Proof of Collaboration that connects\nWeb2 and Web3',
        description_mobile: 'To create a Proof of Collaboration\nthat connects Web2 and Web3',
        learnMore: 'Learn more',
        pocTitle1:'Proof of\n'+'Collaboration',
        pocTitle2:' (PoC)',
        pocContent:'The core concept of Proof of Collaboration: users invest time, energy and funds to participate in various decentralized collaborative tasks in the CyberCrowd ecosystem, and the results of collaboration are presented in the form of commodities, NFTs, etc., while users participate in the allocation of collaborative outcomes in accordance with their contributions.',
        solutionTitle1:'Collaboration',
        solutionTitle2:'-focused solutions',
        solutionContent:'CyberCrowd makes full use of the population and infrastructure of the Web2 centralized service network through connection and empowerment. By utilizing the connection plus aggregation method, the existing community of users and infrastructure of blockchain and Web3 is used to create a collaborative, open and innovative ecosystem.',
        product:{
            stack:"Protocol Stack",
            architecture:"Product Architecture",
            mobileTitle:"Protocol Stack and\nProduct Architecture",
            layer1Title:"Open Platform Protocol Layer",
            layer1Content:{
                web2:"Web2 Access",
                web3:"Web3 Access",
                api:"API/SDK/Plugin/Gateway",
            },
            layer2Title:"Product Protocol Layer",
            layer2Content:{
                content1:"Decentralized Craigslist",
                content2:"Decentralized Alibaba",
                content3:"Decentralized Social EC",
                content4:"Collaborative Commercial Platform",
                content5:"Co-Creation",
            },
            layer3Title:"Core Service Protocol Layer",
            layer3Content:{
                content1:"Decentralized ID & Account",
                content2:"Hybrid Payment Engine",
                content3:"Transaction Engine",
                content4:"Collaboration DAO",
                content5:"Collaboration Engine",
                content6:"Decentralized Bond and Insurance",
                content7:"Decentralized Credit Rating ",
                content8:"Decentralized arbitration",
                content9:"Data Engine",
                content10:"Anti-fraud",
            },
            layer4Title:"Data Protocol Layer",
            layer4Content:{
                content1:"ID Data",
                content2:"Credit Data",
                content3:"Transaction Data",
                content4:"Payment Data",
                content5:"Commodity Data",
                content6:"Social Data",
                content7:"Business Data",
            },
            layer5Title:"Aggregation Protocol Layer",
            layer5Content:{
                web2:"Web2 Aggregator",
                web3:"Web3 Aggregator",
                content1:"Open-source Platform/Fiat Payment/Collaboration\nPlatform/Social Media/E-commerce/Classified Ads/\nCrowd Sourcing/Crowd Funding...",
                mobileContent1:"Open-source Platform/\nFiat Payment/Collaboration Platform/Social Media/E-\ncommerce/Classified Ads/Crowd Sourcing/\nCrowd Funding...",
                content2:"Decentralized ID/NFT Market/\nDecentralized Exchange/Cross-\nchain/Layer2/DeFi/Web3....",
                mobileContent2:"Decentralized ID/NFT Market/Decentralized\nExchange/Cross-chain/Layer2/DeFi/Web3....",
            },
        },
        team:{
            core:"Core Team",
            justin:"Justin",
            justinTitle:"Scientist specialized in Big Data and Cryptography",
            justinDes:" Professor at Nevada University, focusing on big data and information security, long-standing chairman and board member of nearly 160 international conferences, including ASE/IEEE International Conference on Social Computing, International Privacy and Security, as well as PASSAT.",
            molly:"Molly",
            mollyTitle:"Finance and Payments Expert",
            mollyDes:"With over 20 years’ experience in payment sector, including senior consultant at PayPal China, Senior Vice President for Personal Financial Services of HSBC (China) Bank and Chief Representative of MasterCard Shanghai Office.",
            ben:"Ben",
            benTitle:"Blockchain Technology Expert",
            benDes:"With the title of Ph.D. in Blockchain from the University of Malaya, 8 years as a senior blockchain technology expert and senior consultant, he’s had an impressive career in the ICT industry for 18 years.",
            jason:"Jason",
            jasonTitle:"Digital Currency Expert",
            jasonDes:"Famous expert on digital currency.",
            darron:"Darron",
            darronTitle:"Collaboration Expert",
            darronDes:"Expert on collaboration, with 18 years’ experience dedicated in collaborative ecology.",
        }
    },
    solutions:{
        slogan:"CyberCrowd\nCollaboration-focused\nSolutions",
        title1:"The core value of collaboration",
        content1:"Compared with Web2, one of the core values of Web3 is decentralized collaboration. Due to the decentralized collaboration mechanism, the collaboration model in the Web3 era is no longer limited to a single industry chain, but involves multiple industry chains, presenting a network, which further exacerbates the difficulty and efficiency of collaboration.",
        title1_1:"To connect and enable Web2 applications",
        title1_1_mobile:"To connect and enable Web2\napplications",
        content1_1:"Web2 has a large number of user groups and application scenarios, and many users suffer from the monopoly of Web2 giants. If the threshold for use can be lowered down, these users can seamlessly enjoy the experience of Web3 services, and then gradually migrate to Web3 services. This is the only way to popularize Web3.\nThrough the open interfaces of Twitter, Facebook, Google and other platforms, the user's Web2 identity is bound to the CyberCrowd decentralized identity. Users can continue to use the existing Web2 identity, and users do not need to learn anything about wallet.\nThrough the CyberCrowd fiat and encrypto Hybrid payment protocol, the user's deposit and withdrawal problems are well solved. Users can pay the deposit with their familiar fiat currency, and the system automatically converts it into the corresponding Token, with no worry about buying and withdrawing Token using fiat currency.\nBy providing extensions and plug-ins for open-source systems such as Wordpress and Joomla, these systems automatically have the ability of CyberCrowd to decentralize collaboration.",
        title1_2:"Decentralized collaboration",
        content1_2:"Collaboration is one of the core advantages of Web3 over Web2.\nFor example, price is the core element of the game between buyers and sellers in the free market. Buyers want to buy high-quality and cheap things, and sellers want to sell more goods at higher prices.In traditional Web2 e-commerce, the relationship between buyers and sellers is only a simple buy-and-sell relationship, coupled with the intervention and extraction of centralized platforms, resulting in price distortions dissatisfaction among all parties.\nFocusing on the core elements of commodities, money, tasks, as well as innovative ideas, meanwhile with the help of collaborative processes and tools, CyberCrowd's decentralized collaborative mechanism allows buyers to get high-quality but less expensive purchases together with a bunch of other buyers. Sellers can skip the centralized platform and sell more products in a way which is more directly, more efficiently, at lower costs, and with higher profits.",
        title1_3:"Decentralized governance and operations",
        title1_3_mobile:"Decentralized governance and\noperations",
        content1_3:"DAO has become the standard for all decentralized projects, under the existing model, the biggest role of DAO is voting. In the initial stage of the platform, DAO can make do with it, however, along with the scalability of the platform, the somewhat rough governance model is no longer able to support the long-term operation of the platform.\nCyberCrowd's decentralized governance and operation model, via the introduction of a collaborative DAO, can establish a decentralized, bottom-up, multi-level governance system similar to a wellfunctioning democratic system in reality. The current DAO can well resolve the issue of lack of coordination through a decentralized coordination mechanism.",
        title2:"Core Solution",
        content2:"CyberCrowd's belief is that their collaborative scheme to connect and empower the Web2+ decentralized collaboration&decentralizing governance and operation.\nCyberCrowd makes full use of the population and infrastructure of the Web2 centralized service network through connection and empowerment. By utilizing the connection plus aggregation method, the existing community of users and infrastructure of blockchain and Web3 is used to create a collaborative, open and innovative ecosystem.",

        mobileContent2:"CyberCrowd believes that the e-commerce transformation in the Web3 era must be a transformation of the existing collaborative model.E-commerce based on the new collaborative mechanism is bound to profoundly change the e-commerce industry in terms of governance model, operation model, marketing model and innovation model.",
        content3:"The CyberCrowd Decentralized Proof of Collaboration solves the current issues that centralized exchanges and decentralized transactions both face, by connecting and empowering Web2, decentralized collaboration, decentralized governance and operation, as well as decentralized collaborative e-commerce solutions.",
        decentralizedEcommerce:"Decentralized E-commerce:",
        centralizedEcommerce:"Centralized E-commerce:",
        content4:"In traditional centralized e-commerce, including B2C/C2C/B2B/B2B2C, the demand side and the supply side are simply buying and selling.",
        content5:"Under the CyberCrowd decentralized collaborative e-commerce model, decentralized collaboration and decentralized governance are the core lubricants, connecting the core elements of commodities, tasks, innovative ideas, and money to form a new collaborative e-commerce model which includes commodity trading, crowd sourcing, co-creation and crowd funding.",
        content6:"In the decentralized collaborative e-commerce model, here are some interesting changes in its paradigm: ",
    }
}
