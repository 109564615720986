<template>

  <div :class="[{'footer-section':!isMobile},{'footer-section-mobile':isMobile}]">
    <div v-if="!isMobile" class="footer">

      <div class="row1">
        <img class="footer-logo" src="@/assets/images/home/logo_white.png" alt="">
        <div class="email">
          <a href="mailto:business@cybercrowd.org">
            <img class="email-logo" src="@/assets/images/home/email.svg" alt="">
          </a>

          <span>{{ $t('common.businessEmail') }}</span>
        </div>
      </div>

      <div class="line"></div>

      <div class="row2">
        <div class="company"><span style="font-size: 0.18rem">©</span>{{$t('common.company')}}</div>

        <div class="footer-media">
          <img src="@/assets/images/home/telegram.svg" alt="" @click="mediaLink('Telegram')">
          <img src="@/assets/images/home/twitter.svg" alt="" @click="mediaLink('Twitter')">
          <img src="@/assets/images/home/discord.svg" alt="" @click="mediaLink('Discord')">
          <img src="@/assets/images/home/medium.svg" alt="" @click="mediaLink('Medium')">
        </div>

      </div>

    </div>

    <div v-if="isMobile" class="footer">

      <div>
        <div class="email">
          <a href="mailto:business@cybercrowd.org">
            <img class="email-logo" src="@/assets/images/home/email.svg" alt="">
          </a>
          <span>{{ $t('common.businessEmail') }}</span>
        </div>
      </div>

      <div style="margin-top: 0.16rem">
        <div class="footer-media">
          <img src="@/assets/images/home/telegram.svg" alt="" @click="mediaLink('Telegram')">
          <img src="@/assets/images/home/twitter.svg" alt="" @click="mediaLink('Twitter')">
          <img src="@/assets/images/home/discord.svg" alt="" @click="mediaLink('Discord')">
          <img src="@/assets/images/home/medium.svg" alt="" @click="mediaLink('Medium')">
        </div>
      </div>

      <div style="margin-top: 0.19rem">
        <div class="company"><span style="font-size: 0.14rem">©</span>{{$t('common.company')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {media_util} from "@/mixins/mixin";

export default {
  name: "FooterView",
  mixins: [media_util],
  methods:{

  }
}
</script>

<style lang="scss" scoped>

.footer-section{
  background: #20252A;
  width: 100%;

  .footer{
    width: 12rem;
    height: 2rem;
    margin: 0 auto;

    .row1{
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 1.11rem;
      .footer-logo{
        width: 1.6rem;
        height: 0.35rem;
      }

      .email{
        font-size: 0.14rem;
        font-family: PoppinsMedium, sans-serif;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.23rem;
        display: flex;
        align-items: center;

        a{
          margin-right: 0.12rem;
          height: 0.3rem;
          .email-logo{
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }
    }

    .line{
      width: 12rem;
      height: 0.01rem;
      background: #666666;
    }

    .row2{

      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 0.88rem;

      .company{
        font-size: 0.14rem;
        font-family: PoppinsMedium, sans-serif;
        font-weight: 500;
        color: #666666;
        line-height: 0.23rem;
        display: flex;
        align-items: center;
      }

      .footer-media{
        display: flex;

        img{
          width: 0.29rem;
          height: 0.28rem;
          margin-left: 0.17rem;
          cursor: pointer;
          object-fit:cover;
        }
      }
    }
  }
}

.footer-section-mobile{
  background: #20252A;
  width: 100%;
  height: 1.5rem;

  .footer{
    width: 100%;
    padding-top: 0.27rem;

    div{
      display: flex;
      justify-content: center;
    }

    .email{
      font-family: PoppinsMedium, sans-serif;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      font-size: 0.13rem;
      line-height: 0.21rem;

      a{
        margin-right: 0.08rem;
        height: 0.23rem;
        .email-logo{
          width: 0.23rem;
          height: 0.23rem;
        }
      }
    }

    .footer-media{
      display: flex;

      img{
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.09rem;
        margin-right: 0.09rem;
        cursor: pointer;
        object-fit:cover;
      }
    }

    .company{
      font-family: PoppinsMedium, sans-serif;
      font-weight: 500;
      color: #666666;
      display: flex;
      align-items: center;
      font-size: 0.11rem;
      line-height: 0.18rem;
    }
  }
}
</style>
