<template>
  <el-popover
      popper-class="header-selector-popper"
      placement="bottom"
      :visible-arrow="false"
      trigger="hover"
      :close-delay=50
      @show="onShow()"
      @hide="onHide()"
      v-model="selectorVisible">
    <div slot="reference" class="selector-prefix">
      <span class="text" :style="{'color':labelColor}">{{ $t('home.tabs.download') }}</span>
      <img
          ref="arrow"
          class="arrow"
          :class="{'is-active': selectorVisible}"
          :src="isHeaderDark?require('@/assets/images/home/arrow_down_white.svg'):require('@/assets/images/home/arrow_down_black.svg')"
          alt="">
    </div>
    <div slot class="selector-content">
      <div class="item" @click="downloadResources('PPT')">{{ $t('home.tabs.ppt') }}</div>
      <div class="item" @click="downloadResources('WhitePaper')">{{ $t('home.tabs.whitePaper') }}</div>
      <div class="item" @click="downloadResources('OnePage')">{{ $t('home.tabs.onePage') }}</div>
    </div>
  </el-popover>
</template>

<script>

import {media_util} from "@/mixins/mixin";

export default {
  name: "HeaderSelector",
  mixins: [media_util],
  props: {
    labelColor: [String],
    isHeaderDark: [Boolean],
  },
  data() {
    return {
      selectorVisible: false
    }
  },
  created() {

  },
  methods: {
    onShow() {
      console.log("onShow")
      // let img = this.$refs.arrow;
      // img.style.transform = 'rotate(180deg)';
    },
    onHide() {
      console.log("onHide")
      // let img = this.$refs.arrow;
      // img.style.transform = 'rotate(0deg)';
    },
  },
}
</script>

<style lang="scss">
.header-selector-popper {
  padding: 0 !important;
  background: #FFFFFF;
  box-shadow: 0 0.07rem 0.18rem 0 rgba(0, 49, 44, 0.0800);
  border-radius: 0.08rem;

  .selector-content {
    width: 1.6rem;

    &:first-child {
      margin-top: 0.2rem;
    }

    &:last-child {
      margin-bottom: 0.2rem;
    }

    .item {
      margin-top: 0.14rem;
      margin-left: 0.24rem;
      font-size: 0.14rem;
      font-family: PoppinsRegular, sans-serif;
      font-weight: 400;
      color: #020027;
      line-height: 0.23rem;
      cursor: pointer;
    }
  }
}

.header-selector-popper[x-placement^=bottom] {
  margin-top: 0.1rem !important;
}

.selector-prefix {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .15rem;

  .text {
    text-align: center;
    font-family: PoppinsBold, sans-serif;
    color: #FFFFFF;
    line-height: .26rem;
    font-size: .16rem !important;
    -webkit-text-size-adjust: none;
    cursor: pointer;
  }

  .arrow {
    margin-left: 0.1rem;
    width: 0.12rem;
    height: 0.12rem;
    transition: all 0.5s;
    transform: rotate(0deg);
    &.is-active{
      transform:rotate(180deg);
    }
  }
}
</style>
