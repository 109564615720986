<template>
  <div class="tab">
    <div class="tab-header" ref="navHeader">
      <ul class="tab-item clearfix" ref="nav">
        <li
            :class="tabCls(item)"
            v-for="(item, index) in navList"
            :key="index"
            @click="handleChange(index)"
            :style="{'color':tabLabelColor}"
        >
          {{ item.label }}
        </li>
      </ul>
      <div ref="tabBar" class="active-bar-link bar-animated" :style="barStyle"></div>
    </div>
    <div class="tab-panel" ref="panels" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: "TabS",
  data() {
    return {
      navList: [],
      activeKey: this.value,
      // barWidth: 60,
      barOffset: 0
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: [String, Number],
    tabLabelColor: [String],
  },
  provide() {
    return {TabsInstance: this};
  },
  watch: {
    activeKey() {
      this.updateStatus();
      this.updateBar();
    },
    value(newValue) {
      this.activeKey = newValue;
    }
  },
  computed: {
    barStyle() {
      let style = {
        visibility: "visible",
        // width: `${this.barWidth}px`
      };

      // style.transform = `translate3d(${this.divide(this.barOffset,100)}rem, 0px, 0px)`;
      style.transform = `translate3d(${this.barOffset}px, 0px, 0px)`;
      return style;
    },
    contentStyle() {
      const index = this.getTabIndex(this.activeKey);
      return {"margin-left": index > 0 ? `-${index}00%` : 0};
    }
  },
  mounted() {

  },
  methods: {
    tabCls(item) {
      return [
        'tab-item-title', {'tab-active': item.name === this.activeKey}
      ];
    },
    handleChange(index) {
      // this.activeKey = this.navList[index].name;
      // const nav = this.navList[index];
      // this.$emit("change", nav.name);

      this.$emit("tab-click", index);
    },
    getPanels() {
      const TabPanels = this.$children.filter(
          item => item.$options.name === "TabPanel"
      );
      TabPanels.sort((a, b) => {
        if (a.index && b.index) {
          return a.index > b.index ? 1 : -1;
        }
      });
      return TabPanels;
    },

    updateNav() {
      this.navList = [];
      this.getPanels().forEach((panel, index) => {
        this.navList.push({
          label: panel.label,
          name: panel.name || index
        });
        if (!panel.currentName) panel.currentName = index;
        if (index === 0) {
          if (!this.activeKey)
            this.activeKey = panel.currentName || index;
        }
      });
      this.updateStatus();
      this.updateBar();
    },
    updateBar() {
      /*
      setTimeout(()=>{
        const index = this.getTabIndex(this.activeKey);
        if (!this.$refs.nav) return;
        const prevTabs = this.$refs.nav.querySelectorAll(".tab-item-title");
        const tab = prevTabs[index];
        // console.log("tab.offsetWidth == "+tab.offsetWidth)

        //bar的宽度等于tab的宽度
        // this.barWidth = tab ? parseFloat(tab.offsetWidth) : 0;
        this.barWidth =this.$refs.tabBar.offsetWidth;

        if (index > 0) {
          let offset = 0;
          for (let i = 0; i < index; i++) {
            offset += prevTabs[i].offsetWidth;
          }
          // this.barOffset = offset;
          this.barOffset = offset + (tab.offsetWidth - this.barWidth) / 2;
        } else {
          // this.barOffset = 0;
          this.barOffset = (tab.offsetWidth - this.barWidth) / 2;
        }
      },0)
      */
    },
    getTabIndex(name) {
      return this.navList.findIndex(nav => nav.name === name);
    },
    updateStatus() {
      const navs = this.getPanels();
      navs.forEach(
          tab => (tab.show = tab.currentName === this.activeKey)
      );
    }
  }
};
</script>
<style lang="scss" scoped>

.tab {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-font-feature-settings: "normal";
  font-feature-settings: "normal";
  position: relative;
  overflow: hidden;
}

.tab-header {

}

.tab-item {
  list-style: none;
  padding-inline-start: 0;
  margin-block-end: 0;
  cursor: pointer;
}

.tab-item > .tab-item-title {
  position: relative;
  float: left;
  padding: 0 .15rem;
  text-align: center;

  font-family: PoppinsBold, sans-serif;
  color: #FFFFFF;
  line-height: .26rem;
  font-size: .16rem!important;
  /*-webkit-font-smoothing: antialiased;*/
  -webkit-text-size-adjust: none;
}

.tab-item > .tab-item-title.tab-active {
  color: #0FDDCA!important;
}

.tab-item > .tab-item-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
}

.active-bar-link {
  width: 0;
  height: 0;
  background: linear-gradient(270deg, #12EAC4 0%, #0199EF 100%);
  transition: left 0.2s ease;
  border-radius: 0;
}

.active-bar-link .bar-animated {
  -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
  left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
  -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
  width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
  left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
  -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tab-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
  width: 0;
  height: 0;
}

.tab-panel::before {
  display: block;
  overflow: hidden;
  content: "";
}

.tab-panel-content {
  height: 0;
  padding: 0 !important;
  opacity: 0;
  pointer-events: none;
  flex-shrink: 0;
  width: 100%;
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
}

.tab-panel-content.panel-active {
  flex-shrink: 0;
  height: auto;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
}

</style>
