import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './lang/index.js'
import ElementUI from 'element-ui';


import 'animate.css'
import './assets/js/rem.js'
import './assets/css/common.css'
import './assets/css/scroll-animate.css'
import './assets/css/font.css'
import 'element-ui/lib/theme-chalk/index.css';
import {mobile_util} from "@/mixins/mixin";


// import vConsole from 'vconsole'
// new vConsole()

Vue.mixin(mobile_util);
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
