<template>
  <div :class="[{'home':!isMobile},{'home-mobile':isMobile}]">
    <section ref="header" class="header" :style="{'background': isHeaderDark ? '#020027' : '#FFFFFF' }">
      <div class="header-content">
        <img class="logo"
             :src="isHeaderDark?require('@/assets/images/home/logo.png'):require('@/assets/images/home/logo_dark.png')"
             alt=""
             @click="clickLogo">
        <div class="header-tabs">
          <Tabs v-model="activeName" :tabLabelColor="tabLabelColor" @tab-click="handleClick">
            <TabPanel :label="$t('home.tabs.poc')" name="tab1"></TabPanel>
            <TabPanel :label="$t('home.tabs.solution')" name="tab2"></TabPanel>
            <TabPanel :label="$t('home.tabs.product')" name="tab3"></TabPanel>
<!--            <TabPanel :label="$t('home.tabs.team')" name="tab4"></TabPanel>-->
            <TabPanel :label="$t('home.tabs.ecosystem')" name="tab4"></TabPanel>
          </Tabs>

          <HeaderSelector :labelColor="tabLabelColor" :isHeaderDark="isHeaderDark"></HeaderSelector>
        </div>
        <div class="media-section">
          <img
              :src="isHeaderDark?require('@/assets/images/home/telegram.svg'):require('@/assets/images/home/telegram_dark.svg')"
              alt="" @click="mediaLink('Telegram')">
          <img
              :src="isHeaderDark?require('@/assets/images/home/twitter.svg'):require('@/assets/images/home/twitter_dark.svg')"
              alt="" @click="mediaLink('Twitter')">
          <img
              :src="isHeaderDark?require('@/assets/images/home/discord.svg'):require('@/assets/images/home/discord_dark.svg')"
              alt="" @click="mediaLink('Discord')">
          <img
              :src="isHeaderDark?require('@/assets/images/home/medium.svg'):require('@/assets/images/home/medium_dark.svg')"
              alt="" @click="mediaLink('Medium')">
        </div>

        <img
            v-if="isMobile"
            class="menu"
            :src="isHeaderDark?require('@/assets/images/home/menu.svg'):require('@/assets/images/home/menu_dark.svg')"
             alt=""
             @click="showMenu = true">
      </div>
    </section>

    <transition name="slide">
      <div v-if="isMobile" v-show="showMenu" class="menu-section" @touchmove.prevent @mousewheel.prevent>

        <div class="menu-header">

          <img class="menu-logo"
               src="@/assets/images/home/logo_dark.png"
               alt=""
               @click="menuItemClick(-1)">

          <img class="close"
               src="@/assets/images/home/close.svg"
               alt=""
               @click="showMenu = false">
        </div>

        <div class="menu-content">

          <div class="tab" @click="menuItemClick(0)">{{$t('home.tabs.poc')}}</div>
          <div class="tab" @click="menuItemClick(1)">{{$t('home.tabs.solution')}}</div>
          <div class="tab" @click="menuItemClick(2)">{{$t('home.tabs.product')}}</div>
<!--          <div class="tab" @click="menuItemClick(3)">{{$t('home.tabs.team')}}</div>-->
          <div class="tab" @click="menuItemClick(3)">{{$t('home.tabs.ecosystem')}}</div>
          <div class="tab download" @click="showSelector">
            <span>{{$t('home.tabs.download')}}</span>
            <img
                ref="arrow"
                class="arrow"
                :class="{'is-active': menuSelectorVisible}"
                src="@/assets/images/home/arrow_down_black.svg"
                alt="">
          </div>

          <div class="menu-selector-transform" :class="{'is-active':menuSelectorVisible}">
            <div class="item" @click="downloadResources('PPT')">{{ $t('home.tabs.ppt') }}</div>
            <div class="item" @click="downloadResources('WhitePaper')">{{ $t('home.tabs.whitePaper') }}</div>
            <div class="item" @click="downloadResources('OnePage')">{{ $t('home.tabs.onePage') }}</div>

            <div class="line"></div>

            <div class="media-section">
              <img
                  src="@/assets/images/home/telegram_dark.svg"
                  alt="" @click="mediaLink('Telegram')">
              <img
                  src="@/assets/images/home/twitter_dark.svg"
                  alt="" @click="mediaLink('Twitter')">
              <img
                  src="@/assets/images/home/discord_dark.svg"
                  alt="" @click="mediaLink('Discord')">
              <img
                  src="@/assets/images/home/medium_dark.svg"
                  alt="" @click="mediaLink('Medium')">
            </div>
          </div>
        </div>
      </div>
    </transition>


    <!-- Main -->
    <main class="main-content" ref="main">
      <section class="main-section" ref="section1">
        <FirstView :isPlay="isPlay"></FirstView>
      </section>
      <section class="main-section" ref="section2">
        <PoC></PoC>
      </section>
      <section class="main-section" ref="section3">
        <Solution></Solution>
      </section>
      <section class="main-section" ref="section4">
        <Architecture></Architecture>
      </section>
<!--      <section class="main-section" ref="section5">-->
<!--        <Team></Team>-->
<!--      </section>-->
      <section class="main-section" ref="section5">
        <Ecosystem></Ecosystem>
      </section>
    </main>
  </div>
</template>

<script>
import {preLoad, scrollToY} from "@/utils";
import 'animate.css';
import Tabs from "@/components/tabs/Tabs";
import TabPanel from "@/components/tabs/TabPanel";
import FirstView from "@/pages/home/FirstView";
import PoC from "@/pages/home/PoC";
import Solution from "@/pages/home/Solution";
import Architecture from "@/pages/home/Architecture";
import Ecosystem from "@/pages/home/Ecosystem";
import {media_util} from "@/mixins/mixin";
import HeaderSelector from "@/pages/home/HeaderSelector";
// import Team from "@/pages/home/Team";

export default {
  name: "HomePage",
  mixins: [media_util],
  data() {
    return {
      activeName: 'tab',
      isHeaderDark: true,
      tabLabelColor: '#FFFFFF',
      isSmoothScrolling: true,
      headerHeight: 85,
      showMenu:false,
      menuSelectorVisible:false,
      isPlay:true,
    };
  },
  components: {
    Tabs,
    TabPanel,
    FirstView,
    PoC,
    Solution,
    Architecture,
    // Team,
    Ecosystem,
    HeaderSelector,
  },
  watch: {
    isHeaderDark(newVal, oldVal) {
      console.log("isHeaderDark newVal == "+newVal +" oldVal == "+oldVal);
      if (newVal){//isHeaderDark true
        this.tabLabelColor = '#FFFFFF'
      }else {
        this.tabLabelColor = '#020027'
      }
    },
    activeName(newVal, oldVal){
      console.log("activeName newVal == "+newVal +" oldVal == "+oldVal);
      if (newVal === 'tab'){
        this.isPlay = true
      }else {
        this.isPlay = false
      }
    }
  },
  created() {

  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    this.preloadImage();

    setTimeout(() => {
      this.headerHeight = this.$refs.header.offsetHeight;
    }, 0);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeRouteEnter(to, from,next) {
    next(vm => {
      if (from.name === "Solution" && from.params.tabIndex !== null && from.params.tabIndex !== undefined) {
        //vm等于this
        setTimeout(() => {
          vm.handleClick(from.params.tabIndex)
        }, 10);
      }
    })
  },
  methods: {
    handleScroll() {
      let scrollTop = -this.$refs.main.getBoundingClientRect().top

      let blocks = document.querySelectorAll('.main-section');

      if (scrollTop + this.headerHeight >= blocks[1].offsetTop && scrollTop + this.headerHeight < blocks[1].offsetTop + blocks[1].offsetHeight) {
        if (this.isSmoothScrolling) {
          this.activeName = "tab1";
        }
        this.isHeaderDark = false;
      } else if (scrollTop + this.headerHeight >= blocks[2].offsetTop && scrollTop + this.headerHeight < blocks[2].offsetTop + blocks[2].offsetHeight) {
        if (this.isSmoothScrolling) {
          this.activeName = "tab2";
        }
        this.isHeaderDark = false;
      } else if (scrollTop + this.headerHeight >= blocks[3].offsetTop && scrollTop + this.headerHeight < blocks[3].offsetTop + blocks[3].offsetHeight) {
        if (this.isSmoothScrolling) {
          this.activeName = "tab3";
        }
        this.isHeaderDark = false;
      }else if (scrollTop + this.headerHeight >= blocks[4].offsetTop && scrollTop + this.headerHeight < blocks[4].offsetTop + blocks[4].offsetHeight) {
        if (this.isSmoothScrolling) {
          this.activeName = "tab4";
        }
        this.isHeaderDark = false;
      }
      // else if (scrollTop + this.headerHeight >= blocks[5].offsetTop && scrollTop + this.headerHeight < blocks[5].offsetTop + blocks[5].offsetHeight) {
      //   if (this.isSmoothScrolling) {
      //     this.activeName = "tab5";
      //   }
      //   this.isHeaderDark = true;
      // }
      else {
        if (this.isSmoothScrolling) {
          this.activeName = "tab";
        }
        this.isHeaderDark = true;
      }

      /*
      if (this.isSmoothScrolling) {
        blocks.forEach((item, index) => {
          if (scrollTop + this.headerHeight >= item.offsetTop &&
              scrollTop + this.headerHeight < item.offsetTop + item.offsetHeight) {
            console.log("active index == ", index)
            this.activeName = 'tab' + index;
          }
        })
      }
      */
    },

    handleClick(index) {
      let blocks = document.querySelectorAll('.main-section');

      this.activeName = "tab" + (index + 1);
      this.isSmoothScrolling = false
      let SCROLL_DURATION = 500
      let scrollDistance = 0
      let offsetTop = blocks[index + 1].offsetTop
      scrollDistance = offsetTop - this.headerHeight;
      scrollToY(scrollDistance, SCROLL_DURATION);

      setTimeout(() => {
        this.isSmoothScrolling = true
      }, SCROLL_DURATION + 20);
    },
    clickLogo() {
      this.handleClick(-1)
    },
    menuItemClick(index){
      this.showMenu = false
      this.handleClick(index);
    },
    showSelector(){
      this.menuSelectorVisible = !this.menuSelectorVisible
    },
    preloadImage() {
      let logo_dark = location.protocol + '//' + location.host + require('@/assets/images/home/logo_dark.png');
      preLoad(logo_dark);

      let arrow_down_black = location.protocol + '//' + location.host + require('@/assets/images/home/arrow_down_black.svg');
      preLoad(arrow_down_black);

      let telegram_dark = location.protocol + '//' + location.host + require('@/assets/images/home/telegram_dark.svg');
      preLoad(telegram_dark);

      let twitter_dark = location.protocol + '//' + location.host + require('@/assets/images/home/twitter_dark.svg');
      preLoad(twitter_dark);

      let discord_dark = location.protocol + '//' + location.host + require('@/assets/images/home/discord_dark.svg');
      preLoad(discord_dark);

      let medium_dark = location.protocol + '//' + location.host + require('@/assets/images/home/medium_dark.svg');
      preLoad(medium_dark);
    }
  }
}
</script>

<style lang="scss">

.home {
  .header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;

    .header-content {
      width: 12rem;
      height: 0.8rem;
      display: flex;
      margin: 0 auto;
      align-items: center;
      position: relative;

      .logo {
        width: 1.6rem;
        height: 0.35rem;
        position: absolute;
        left: 0;
        cursor: pointer;
      }

      .header-tabs {
        margin: 0 auto;
        display: flex;
      }

      .media-section {
        display: flex;
        position: absolute;
        right: 0;

        img {
          width: 0.29rem;
          height: 0.28rem;
          margin-left: 0.17rem;
          cursor: pointer;
        }
      }
    }
  }

  .main-content {
    overflow: hidden;
    background: #FAFAFA;

    .main-section {
      display: flex;
    }
  }
}

.home-mobile {
  height: 100%;
  .header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;

    .header-content {
      width: 100%;
      height: 0.56rem;
      display: flex;
      margin: 0 auto;
      align-items: center;
      position: relative;

      .logo {
        width: 1.07rem;
        height: 0.24rem;
        position: absolute;
        left: 0.2rem;
        cursor: pointer;
      }

      .header-tabs {
        margin: 0 auto;
        display: none;
      }

      .menu{
        position: absolute;
        right: 0.2rem;
        width: 0.22rem;
        height: 0.22rem;
      }

      .media-section {
        display: none;
        position: absolute;
        right: 0;

        img {
          width: 0.29rem;
          height: 0.28rem;
          margin-left: 0.17rem;
          cursor: pointer;
        }
      }
    }
  }

  .main-content {
    overflow: hidden;
    background: #FAFAFA;

    .main-section {
      display: flex;
    }
  }

  .menu-section{
    position: fixed;
    z-index: 100;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: #FFFFFF;
    //transition: all 2s;
    //transform: translateX(1rem);

    .menu-header{
      width: 100%;
      height: 0.56rem;
      display: flex;
      margin: 0 auto;
      align-items: center;
      position: relative;

      .menu-logo {
        width: 1.07rem;
        height: 0.24rem;
        position: absolute;
        left: 0.2rem;
        cursor: pointer;
      }

      .close{
        position: absolute;
        right: 0.2rem;
        width: 0.18rem;
        height: 0.18rem;
      }
    }

    .menu-content{
      padding: 0 0.2rem;

      .tab{
        font-size: 0.14rem;
        font-family: PoppinsMedium, sans-serif;
        font-weight: 500;
        color: #020027;
        line-height: 0.23rem;
        padding: 0.15rem 0;
        margin-top: -0.01rem;
        margin-bottom: -0.01rem;

        position: relative;
        z-index: 10;
        background: #FFFFFF;
      }

      .download{
        display: flex;
        align-items: center;

        .arrow{
          margin-left: 0.1rem;
          width: 0.12rem;
          height: 0.12rem;
          transition: all 0.5s;
          transform: rotate(0deg);
          &.is-active{
            transform:rotate(180deg);
          }
        }
      }

      .menu-selector-transform{
        transition: transform 0.3s;
        transform: translateY(-1.29rem);
        position: relative;
        z-index: 5;

        &.is-active{
          transform: translateY(0);
        }

        .item{
          padding: 0.12rem 0.1rem;
          font-size: 0.12rem;
          font-family: PoppinsRegular, sans-serif;
          font-weight: 400;
          color: #020027;
          line-height: 0.19rem;
        }

        .line{
          margin-top: 0.18rem;
          width: 100%;
          height: 1px;
          background: #D3D3D3;
        }

        .media-section {
          display: flex;
          margin-top: 0.3rem;

          img {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.18rem;
            cursor: pointer;
          }
        }
      }

    }
  }
}

.slide-enter-active, .slide-leave-active {
  //transition: opacity .5s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slide-enter, .slide-leave-to {
  //opacity: 0
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
</style>
