<template>
  <div :class="[{'solution-section2':!isMobile},{'solution-section2-mobile':isMobile}]">

    <div class="title" style="margin-top: 0.6rem;">{{ $t('solutions.title1') }}</div>
    <p class="content" style="margin-top: 0.16rem;">{{ $t('solutions.content1') }}</p>


    <div class="sub-title" :style="{'margin-top':isMobile?'0.3rem':'0.32rem'}">
      <div :class="[{'indicator':!isMobile},{'indicator_2':isMobile}]"></div>
      <span>{{ $t(isMobile?'solutions.title1_1_mobile':'solutions.title1_1') }}</span>
    </div>
    <p class="content" style="margin-top: 0.14rem;">{{ $t('solutions.content1_1') }}</p>


    <div class="sub-title" :style="{'margin-top':isMobile?'0.3rem':'0.32rem'}">
      <div class="indicator"></div>
      <span>{{ $t('solutions.title1_2') }}</span>
    </div>
    <p class="content" style="margin-top: 0.14rem;">{{ $t('solutions.content1_2') }}</p>


    <div class="sub-title" :style="{'margin-top':isMobile?'0.3rem':'0.32rem'}">
      <div :class="[{'indicator':!isMobile},{'indicator_2':isMobile}]"></div>
      <span>{{ $t(isMobile?'solutions.title1_3_mobile':'solutions.title1_3') }}</span>
    </div>
    <p class="content" style="margin-top: 0.14rem;">{{ $t('solutions.content1_3') }}</p>

    <div class="title" style="margin-top: 0.5rem;">{{ $t('solutions.title2') }}</div>
    <img class="image" :style="{'margin-top':isMobile?'0.3rem':'0.35rem'}" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/solution/solution_core_220930.png" alt="">
    <p class="content bottom-content">{{ $t('solutions.content2') }}</p>

  </div>
</template>

<script>

export default {
  name: "SolutionSection2",
  data() {
    return {}
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.solution-section2 {
  width: 12rem;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    width: 12rem;
    font-family: PoppinsBold, sans-serif;
    color: #020027;
    text-align: justify;
    white-space: pre-line;
    font-size: 0.3rem;
    line-height: 0.49rem;
  }

  .sub-title {
    width: 12rem;
    font-family: PoppinsRegular, sans-serif;
    font-weight: 400;
    font-size: 0.24rem;
    color: #020027;
    line-height: 0.4rem;
    display: flex;
    align-items: center;

    .indicator{
      width: 0.04rem;
      height: 0.17rem;
      background: #00E1CA;
      margin-right: 0.1rem;
    }
  }

  .content {
    width: 12rem;
    font-size: 0.16rem;
    font-family: PoppinsRegular, sans-serif;
    font-weight: 400;
    color: #4D4D4D;
    line-height: 0.3rem;
    white-space: pre-line;
    text-align: justify;
  }

  .table-image {
    width: 12rem;
    box-shadow: 0 0.07rem 0.18rem 0 rgba(0, 49, 44, 0.0800);
    border-radius: 0.12rem;
  }

  .image {
    width: 11rem;
    margin-top: 0.5rem;
  }

  .bottom-content{
    margin-top: 0.28rem;
    margin-bottom: 1.4rem;;
  }
}

.solution-section2-mobile {
  width: 100%;
  padding: 0 0.2rem;

  .title {
    width: calc(100% - 0.4rem);
    font-family: PoppinsBold, sans-serif;
    color: #020027;
    //text-align: justify;
    white-space: pre-line;
    font-size: 0.2rem;
    line-height: 0.28rem;
  }

  .sub-title {
    width: calc(100% - 0.4rem);
    font-family: PoppinsRegular, sans-serif;
    font-size: 0.16rem;
    line-height: 0.22rem;
    font-weight: 400;
    color: #020027;
    display: flex;
    align-items: center;

    .indicator{
      width: 0.03rem;
      height: 0.12rem;
      background: #00E1CA;
      margin-right: 0.08rem;
    }

    .indicator_2{
      width: 0.03rem;
      height: 0.38rem;
      background: #00E1CA;
      margin-right: 0.08rem;
    }
  }

  .content {
    width: calc(100% - 0.4rem);
    font-family: PoppinsRegular, sans-serif;
    font-weight: 400;
    color: #4D4D4D;
    white-space: pre-line;
    //text-align: justify;
    font-size: 0.13rem;
    line-height: 0.28rem;
  }

  .table-image {
    width: calc(100% - 0.4rem);
    box-shadow: 0 0.08rem 0.2rem -0.08rem rgba(16, 16, 16, 0.0600);
    border-radius: 0.12rem;
  }

  .image {
    width: calc(100% - 0.4rem);
  }

  .bottom-content{
    margin-top: 0.28rem;
    margin-bottom: 0.6rem;;
  }
}

</style>
