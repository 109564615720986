<template>
  <div :class="[{'first-elements':!isMobile},{'first-elements-mobile':isMobile}]">
    <img class="planet1" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/planet1.png" alt="">
    <img class="planet2" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/planet2.png" alt="">
    <img class="ufo animate__animated animate__zoomInRight" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/ufo.png" alt="">
    <img class="astronaut" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/astronaut.png" alt="">

    <img class="star1" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/star.png" alt="">
    <img class="star2" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/star.png" alt="">
    <img class="star3" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/star.png" alt="">
    <img v-if="isMobile" class="star4" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/first_section/star.png" alt="">
  </div>
</template>

<script>
export default {
  name: "FirstViewElements"
}
</script>

<style lang="scss" scoped>
.first-elements{
  width: 7.84rem;
  height: 6.5rem;
  position: relative;

  .planet1{
    width: 1.52rem;
    height: 1.52rem;
    position: absolute;
    right: 0.23rem;
    top: 0;
    animation:planet1-move 10s infinite;
    animation-delay:0.8s;
  }

  .planet2{
    width: 2.82rem;
    height: 2.82rem;
    position: absolute;
    right: 0;
    bottom: 0;
    animation:planet2-move 8s infinite;
  }

  .ufo{
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 0;
    bottom: 1.76rem;
    animation:ufo-move 3s;
  }

  .astronaut{
    width: 4.64rem;
    height: 4.64rem;
    position: absolute;
    right: 1.37rem;
    bottom: 0.8rem;
    animation:astronaut-move 5s infinite;
  }

  .star1{
    width: 0.27rem;
    height: 0.27rem;
    position: absolute;
    top: 0.58rem;
    left: 3.52rem;
    animation:pulse 1.6s infinite;
  }

  .star2{
    width: 0.23rem;
    height: 0.23rem;
    position: absolute;
    right: 0.53rem;
    top: 1.79rem;
    animation:pulse 1.4s infinite;
    animation-delay:0.5s;
  }

  .star3{
    width: 0.23rem;
    height: 0.23rem;
    position: absolute;
    bottom: 0.39rem;
    right: 3.75rem;
    animation:pulse 1.4s infinite;
    animation-delay:1s;
  }
}

.first-elements-mobile{
  width: 100%;
  height: 5.6rem;
  position: relative;

  .planet1{
    width: 1.24rem;
    height: 1.24rem;
    position: absolute;
    top: 2.19rem;
    left: -0.38rem;
    animation:planet1-move 10s infinite;
    animation-delay:0.8s;
  }

  .planet2{
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 0.14rem;
    bottom: 0.4rem;
    animation:planet2-move 8s infinite;
  }

  .ufo{
    width: 0.9rem;
    height: 0.9rem;
    position: absolute;
    top: 0.42rem;
    right: 0.26rem;
    animation:ufo-move-mobile 3s;
  }

  .astronaut{
    width: 2.96rem;
    height: 2.96rem;
    position: absolute;
    top: 1.48rem;
    right: 0.36rem;
    animation:astronaut-move 5s infinite;
  }

  .star1{
    width: 0.16rem;
    height: 0.16rem;
    position: absolute;
    left: 1.37rem;
    bottom: 0.6rem;
    animation:pulse 1.6s infinite;
  }

  .star2{
    width: 0.15rem;
    height: 0.15rem;
    position: absolute;
    right: 0.38rem;
    top: 0;
    animation:pulse 1.4s infinite;
    animation-delay:0.5s;
  }

  .star3{
    width: 0.13rem;
    height: 0.13rem;
    position: absolute;
    top: 1.15rem;
    left: 1.2rem;
    animation:pulse 1.4s infinite;
    animation-delay:1s;
  }

  .star4{
    width: 0.13rem;
    height: 0.13rem;
    position: absolute;
    right: 0.28rem;
    bottom: 2.6rem;
    animation:pulse 1.4s infinite;
    animation-delay:1s;
  }
}

@keyframes astronaut-move {
  from,
  to {
    -webkit-transform: translate3d(-8px, -8px, 0);
    transform: translate3d(-8px, -8px, 0);
  }

  50% {
    -webkit-transform: translate3d(8px, 8px, 0);
    transform: translate3d(8px, 8px, 0);
  }
}

@keyframes planet1-move {
  from,
  to {
    -webkit-transform: translate3d(-6px, -3px, 0);
    transform: translate3d(-6px, -3px, 0);
  }

  50% {
    -webkit-transform: translate3d(6px, 3px, 0);
    transform: translate3d(6px, 3px, 0);
  }
}

@keyframes planet2-move {
  from,
  to {
    -webkit-transform: translate3d(-8px, 4px, 0);
    transform: translate3d(-8px, 4px, 0);
  }

  50% {
    -webkit-transform: translate3d(8px, -4px, 0);
    transform: translate3d(8px, -4px, 0);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ufo-move {
  from {
    opacity: 0.2;
    -webkit-transform: scale3d(0.2, 0.2, 0.2) translate3d(4rem, -0.8rem, 0);
    transform: scale3d(0.2, 0.2, 0.2) translate3d(4rem, -0.8rem, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

@keyframes ufo-move-mobile {
  from {
    opacity: 0.2;
    -webkit-transform: scale3d(0.2, 0.2, 0.2) translate3d(-2rem, -0.8rem, 0);
    transform: scale3d(0.2, 0.2, 0.2) translate3d(-2rem, -0.8rem, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
</style>
