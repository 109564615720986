<template>
  <div :class="[{'poc-section':!isMobile},{'poc-section-mobile':isMobile}]">

    <div v-if="isMobile" class="description poc-reveal">
      <div class="title">{{$t('home.pocTitle1')}}<span>{{$t('home.pocTitle2')}}</span></div>
      <div class="content">{{$t('home.pocContent')}}</div>
    </div>

    <img class="image poc-reveal" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/poc_220930.png" alt="">

    <div v-if="!isMobile" class="description poc-reveal">
      <div class="title">{{$t('home.pocTitle1')}}<span>{{$t('home.pocTitle2')}}</span></div>
      <div class="content">{{$t('home.pocContent')}}</div>
    </div>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";

export default {
  name: "PocSection",
  data(){
    return{
      scrollReveal:ScrollReveal()
    }
  },
  mounted() {
    this.scrollReveal.reveal('.poc-reveal', {
      // 动画的时长
      // duration: 2000,
      // 延迟时间
      // delay: 2000,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      // origin: 'top',
      // 回滚的时候是否再次触发动画
      reset: false,
      scale: 1,
      opacity: 1,
      beforeReveal: function (el) {
        //添加动画
        el.classList.add('animate__animated', 'animate__fadeInUp');
      },
      beforeReset: function (el) {
        //移除动画，否则会冲突
        el.classList.remove('animate__animated')
        el.classList.remove('animate__fadeInUp')
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.poc-section{
  width: 12rem;
  height: 7rem;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .image{
    width: 6.4rem;
    //height: 5.5rem;
  }

  .description{
    width: 4.78rem;
    align-items: center;

    .title{
      font-size: 0.4rem;
      font-family: PoppinsBold,sans-serif;
      color: #020027;
      line-height: 0.56rem;
      letter-spacing: 1px;
      white-space: pre-line;
      span{
        color: #0FDDCA;
      }
    }

    .content{
      margin-top: 0.34rem;
      font-size: 0.15rem;
      font-family: PoppinsRegular,sans-serif;
      font-weight: 400;
      color: #5D5D5D;
      line-height: 0.28rem;
    }
  }
}

.poc-section-mobile{
  width: 100%;
  padding: 0.8rem 0.2rem 0.2rem;
  display: block;

  .image{
    margin-top: 0.3rem;
    width: calc(100% - 0.2rem);
    padding: 0.1rem;
    //background: #FFFFFF;
    //box-shadow: 0 0.08rem 0.2rem -0.08rem rgba(16,16,16,0.0600);
    //border-radius: 0.12rem;
  }

  .description{
    width: 100%;

    .title{
      font-size: 0.24rem;
      font-family: PoppinsBold,sans-serif;
      color: #020027;
      letter-spacing: 1px;
      white-space: pre-line;
      line-height: 0.36rem;
      span{
        color: #0FDDCA;
      }
    }

    .content{
      margin-top: 0.3rem;
      font-family: PoppinsRegular,sans-serif;
      font-weight: 400;
      color: #5D5D5D;
      line-height: 0.28rem;
      font-size: 0.14rem;
    }
  }
}

</style>
