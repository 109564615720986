<template>
  <div :class="[{'content':!isMobile},{'content-mobile':isMobile}]">

    <div class="image-wrap">
      <img class="banner-element" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/solution/solution_element_220812.png" alt="">
    </div>

    <div class="banner-content-wrap">
      <div class="banner-content">
        <div class="slogan">{{$t('solutions.slogan')}}</div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionSection1"
}
</script>

<style lang="scss" scoped>

.content{
  width: 100%;
  height: 8.5rem;
  position: relative;
  overflow: hidden;

  .image-wrap{
    width: 12rem;
    margin: 0 auto;
    .banner-element{
      float: right;
      width: 7rem;
      margin-right: -0.5rem;
      margin-top: 2.6rem;
      margin-bottom: 0.5rem;
    }
  }

  .banner-content-wrap{
    position: absolute;
    width: 100%;
    height: 100%;

    .banner-content{
      width: 12rem;
      margin: 0 auto;

      .slogan{
        margin-top: 1.91rem;
        font-size: 0.66rem;
        font-family: PoppinsBold,sans-serif;
        color: #020027;
        line-height: 1.09rem;
        white-space: pre-line;
      }

      .divider{
        width: 0.61rem;
        height: 0.04rem;
        background: linear-gradient(270deg, #12EAC4 0%, #0199EF 100%);
        border-radius: 0.03rem;
        margin-top: 0.46rem;
      }
    }
  }
}

.content-mobile{
  width: 100%;

  .image-wrap{
    .banner-element{
      height: 2.85rem;
      margin-left: 0.2rem;
      margin-top: 0.97rem;
    }
  }

  .banner-content-wrap{
    width: 100%;
    margin-top: 0.13rem;

    .banner-content{
      width: 100%;
      padding: 0 0.2rem;

      .slogan{
        font-family: PoppinsBold,sans-serif;
        color: #020027;
        font-size: 0.28rem;
        line-height: 0.46rem;
        white-space: pre-line;
      }

      .divider{
        margin-top: 0.4rem;
        margin-bottom: 0.6rem;
        width: 0.56rem;
        height: 0.05rem;
        background: linear-gradient(270deg, #12EAC4 0%, #0199EF 100%);
        border-radius: 0.03rem;
      }
    }
  }

}
</style>
