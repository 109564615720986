import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/lang";

Vue.use(VueRouter)

//组件引入
//首页
// const Home = () => import('../pages/home/Index.vue');
// const Solution = () => import('../pages/solution/Index.vue');

import Home from '../pages/home/Index.vue'
import Solution from '../pages/solution/Index.vue'

//页面路由
const routes = [
    //首页
    {path: '/', name: "Home", component: Home,meta: {title: "common.indexTitle"}},
    {path: '/solution', name: "Solution", component: Solution, meta: {title: "common.solutionTitle"}},

]

const router = new VueRouter({
    // mode:'history',
    routes
})
router.afterEach((to) => {
    if (to.meta.title) {
        document.title =  i18n.t(to.meta.title)
        window.gtag('config', 'G-9WSZGJTDYV', {
            page_title: document.title,
            page_path: to.fullPath
        })
    }
})

export default router
