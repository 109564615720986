<template>
  <div id="app">

    <div ref="router">
      <router-view :class="[{'router-view':!isMobile},{'router-view-mobile':isMobile}]"></router-view>
    </div>

    <Footer v-if="footerVisible" ref="footer"></Footer>

  </div>
</template>

<script>
import Footer from "@/components/Footer";
import elementResizeDetectorMaker from "element-resize-detector";

export default {
  name: 'App',
  data() {
    return {
      footerVisible: false
    }
  },
  components: {
    Footer
  },
  created() {

  },
  computed: {},
  mounted() {
    this.watchSize();
  },

  methods: {
    watchSize() {
      //高度大于0 说明页面已经渲染完毕，否则会优先展示底部栏
      let _this = this;
      let erd = elementResizeDetectorMaker({
        strategy: "scroll" //<- For ultra performance.
      });
      erd.listenTo(_this.$refs.router, (element) => {
        let height = element.offsetHeight;
        _this.$nextTick(() => {
          // 这里填写监听改变后的操作
          // console.log("router height == " + height)
          if (height > 0) {
            this.footerVisible = true
          }
        });
      });
    },
  },
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  position: relative;
}

.router-view {
  //1rem 是footer的高度
  min-height: calc(100% - 2rem);
}

.router-view-mobile {
  //1rem 是footer的高度
  min-height: calc(100% - 1.5rem);
}

</style>
