<template>
  <div :class="[{'ecosystem-section':!isMobile},{'ecosystem-section-mobile':isMobile}]">
    <div class="title">{{ $t('home.tabs.ecosystem') }}</div>
    <img v-if="!isMobile" class="image" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/ecosystem_220930.png" alt="">
    <img v-if="isMobile" class="image" src="https://cybercrowd-bucket.oss-ap-southeast-1.aliyuncs.com/official_website_source/images/home/m_ecosystem_221001.png" alt="">
  </div>
</template>

<script>

export default {
  name: "EcosystemSection",
  mounted() {

  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.ecosystem-section {
  width: 12rem;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;

  .title {
    margin: 0.8rem auto 0;
    font-size: 0.4rem;
    font-family: PoppinsBold, sans-serif;
    font-weight: 800;
    color: #020027;
    line-height: 0.48rem;
    letter-spacing: 1px;
  }

  .image {
    margin-top: 0.6rem;
    margin-bottom: 1.4rem;
    width: 100%;
  }
}

.ecosystem-section-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    margin: 0.6rem auto 0;
    font-family: PoppinsBold, sans-serif;
    font-weight: 800;
    color: #020027;
    letter-spacing: 1px;
    font-size: 0.24rem;
    line-height: 0.36rem;
  }

  .image {
    margin-top: 0.5rem;
    margin-bottom: 0.56rem;
    width: 100%;
  }
}
</style>
